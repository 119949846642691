import mock from "../mock";
import { paginateArray, sortCompare } from "../utils";

const data = {
  posts: [
    {
      _id: 1,
      title: "Danang FantastiCity",
      content:
        "<p>The entry among top 26 most loved and having the best contest selected by the organizers will win Danang Fantastic Heart, including 300,000 won gift voucher and one 4-day-3-night all-inclusive trip to Da Nang by HanaTour.</p>",
      createdAt: "11/11/2021 14:40:35",
      thumbnail: require("@/assets/properties/apartment1.jpg"),
      modifiedAt: "11/11/2021 14:40:35",
    },
    {
      _id: 2,
      title: "Danang FantastiCity",
      content:
        "<p>The entry among top 26 most loved and having the best contest selected by the organizers will win Danang Fantastic Heart, including 300,000 won gift voucher and one 4-day-3-night all-inclusive trip to Da Nang by HanaTour.</p>",
      createdAt: "11/11/2021 14:40:35",
      thumbnail: require("@/assets/properties/apartment1.jpg"),
      modifiedAt: "11/11/2021 14:40:35",
    },
    {
      _id: 3,
      title: "Danang FantastiCity",
      content:
        "<p>The entry among top 26 most loved and having the best contest selected by the organizers will win Danang Fantastic Heart, including 300,000 won gift voucher and one 4-day-3-night all-inclusive trip to Da Nang by HanaTour.</p>",
      createdAt: "11/11/2021 14:40:35",
      thumbnail: require("@/assets/properties/apartment1.jpg"),
      modifiedAt: "11/11/2021 14:40:35",
    },
    {
      _id: 4,
      title: "Danang FantastiCity",
      content:
        "<p>The entry among top 26 most loved and having the best contest selected by the organizers will win Danang Fantastic Heart, including 300,000 won gift voucher and one 4-day-3-night all-inclusive trip to Da Nang by HanaTour.</p>",
      createdAt: "11/11/2021 14:40:35",
      thumbnail: require("@/assets/properties/apartment1.jpg"),
      modifiedAt: "11/11/2021 14:40:35",
    },
  ],
};

//POST: Add new post
mock.onPost("/post").reply((config) => {
  // Get event from post data
  const { post } = JSON.parse(config.data);

  const { length } = data.posts;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.posts[length - 1]._id;
  }
  post._id = lastIndex + 1;

  data.posts.push(post);

  return [201, { post }];
});

//GET: Return single post
mock.onGet(/\/post\/\d+/).reply((config) => {
  // Get event _id from URL
  let postId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  postId = Number(postId);

  const postIndex = data.posts.findIndex((e) => e._id === postId);
  const post = data.posts[postIndex];

  if (post) return [200, post];
  return [404];
});

//GET: Return limit posts in homepage
mock.onGet("/post-limit").reply(() => [200, data.posts]);

//GET: Return posts
mock.onGet("/post").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = "_id",
    sortDesc = false,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.posts.filter((post) =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    post.title.toLowerCase().includes(queryLowered)
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      posts: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

//POST: Update post
mock.onPost(/\/post\/\d+/).reply((config) => {
  const { post: postData } = JSON.parse(config.data);

  // Convert Id to number
  postData._id = Number(postData._id);

  const post = data.posts.find((e) => e._id === Number(postData._id));
  Object.assign(post, postData);

  return [200, { post }];
});

// DELETE: Remove post
mock.onDelete(/\/post\/\d+/).reply((config) => {
  // Get post _id from URL
  let postId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  postId = Number(postId);

  const post = data.posts.find((p) => p._id === postId);
  Object.assign(post, { isDeleted: true });
  return [200];
});
