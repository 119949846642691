import Vue from "vue";
import VueI18n from "vue-i18n";
import jaMessage from "./ja.json";
import enMessage from "./en.json";
Vue.use(VueI18n);
const messages = {
  ja: jaMessage,
  en: enMessage,
};

const i18n = new VueI18n({
  locale: "ja",
  fallbackLocale: "en",
  messages,
});
export default i18n;
