import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "axios";
import { store } from "./store";
import i18n from "@/libs/i18n";
// Axios Mock Adapter
import "../src/@fake-db/db";

// Custiomize Bootstrap
import "@/styles/custom.scss";

// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

//Form-wizard
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
Vue.use(VueFormWizard);

//Format Date
Vue.filter("format1", function (value) {
  return value.split("T")[0].replace("-", "年").replace("-", "月") + "日";
});
Vue.filter("format", function (value) {
  return value.split("T")[0].replaceAll("-", ".");
});

// VueX
//Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Vue form slider range cost
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
Vue.component("VueSlider", VueSlider);


// Vue google map
import * as VueGoogleMaps from "vue2-google-maps"; // Import package

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCPYX-df-wDth7pRnxf_L8SNIaedHeK-Mo",
    libraries: 'places',
  },
});

export default new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: (h) => h(App),
});
