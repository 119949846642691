import Vue from "vue";
import Vuex from "vuex";
import { AUTH_REQUEST, AUTH_LOGOUT, SEARCH } from "./constants";
import axiosConfig from "@/libs/axiosConfig";
import jwtDecode from "jwt-decode";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    search_result: {},
    token: localStorage.getItem("token") || "",
    role: localStorage.getItem("role") || "",
    users: [],
    carousels: [],
  },
  getters: {
    isAuthenticated: (state) => state.token != "",
    getRole: (state) => state.role,
    getCarousels: (state) => state.carousels,
  },
  actions: {
    async fetchCarousels({ commit }, payload) {
      let res = await axiosConfig.get("/carousels");
      commit("SET_CAROUSELS", res.data.images);
    },
    ////   login
    [AUTH_REQUEST]: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axiosConfig
          .post("/user/login", payload.data)
          .then((response) => {
            // console.log(response)
            localStorage.setItem("token", response.data.access_token);
            localStorage.setItem(
              "role",
              jwtDecode(response.data.access_token).role
            );
            commit(AUTH_REQUEST, {
              token: response.data.access_token,
              role: jwtDecode(response.data.access_token).role,
            });
            resolve(response);
          })
          .catch(function (error) {
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            reject(error);
          });
      });
    },
    //// logout
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        commit(AUTH_LOGOUT, { token: "", role: "" });
        resolve();
      });
    },
  },
  mutations: {
    SET_CAROUSELS(state, listCarousel) {
      state.carousels = listCarousel;
    },
    ADD_CAROUSEL(state, image) {
      state.carousels.push(image);
    },
    DELETE_CAROUSEL(state, image) {
      const index = state.carousels.findIndex((x) => x.includes(image));
      if (index > -1) state.carousels.splice(index, 1);
    },
    [AUTH_REQUEST]: (state, payload) => {
      state.token = payload.token;
      state.role = payload.role;
    },
    [AUTH_LOGOUT]: (state, payload) => {
      state.token = payload.token;
      state.role = payload.role;
    },
    // Search
    [SEARCH]: (state, payload) => {
      state.search_result = {
        properties: payload.result.properties,
        totalItems: payload.result.totalItems,
        term: payload.term,
      };
    },
  },
});

export { store };
