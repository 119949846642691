import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import( "@/views/home/Home.vue")
const FavoutitesList = () => import( "./views/member-only/favourites-list.vue")
const History = () => import( "./views/member-only/history.vue")
const Explore = () => import( "./views/properties/explore/explore.vue")
const Register = () => import( "./views/user/register/register.vue")
const PropertyDetails = () => import( "./views/properties/property-details.vue")
const Login = () => import( "./views/user/login/login.vue")
const PostDetail = () => import( "./views/post/post-detail.vue")
const CompanyProfile = () => import( "./views/company/profile.vue")
const ContactUs = () => import( "./views/company/contact-us.vue")
const ForgotPassword = () => import( "./views/user/forgotPassword/forgot-password.vue")
const ResetPassword = () => import( "./views/user/forgotPassword/reset-password.vue")
const FilterResult = () => import( "./views/filter/Filter.vue")
const NotFound = () => import( "./views/Notfound.vue")
const SearchResult = () => import( "./views/search/SearchResult.vue")
const CreateProperty = () => import( "./views/admin/CreateProperty.vue")
const CreatePost = () => import( "./views/admin/CreatePost.vue")
const Contact = () => import( "./views/admin/Contact.vue")
const CustomiseCarousel = () => import( "./views/admin/CustomiseCarousel.vue")
import { store } from "@/store"

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifIsUser = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.getRole === "user") {
    next();
    return;
  }
  next("/login");
};

const ifIsAdmin = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.getRole === "admin") {
    next();
    return;
  }
  next("/notfound");
};

const routes = [
  // home
  { path: "/", name: "Home", component: Home },

  // company
  {
    path: "/profile",
    component: CompanyProfile,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },

  //user
  { path: "/login", component: Login, beforeEnter: ifNotAuthenticated }, //User login
  { path: "/register", component: Register, beforeEnter: ifNotAuthenticated }, //User registration
  {
    path: "/forgot-password",
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/reset-password/:token",
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated,
  },

  // member-only
  {
    path: "/favourites",
    component: FavoutitesList,
    name: "FavoritesList",
    beforeEnter: ifIsUser,
  },
  {
    path: "/history",
    component: History,
    name: "History",
    beforeEnter: ifIsUser,
  },
  {
    path: "/property/:property_id",
    component: PropertyDetails,
  },

  {
    path: "/explore/:area_id",
    component: Explore,
  },

  {
    path: "/post/:post_id",
    name: "PostDetail",
    component: PostDetail,
  },

  // display filter result

  { path: "/filter", component: FilterResult },

  // display search result

  {
    path: "/search-result",
    component: SearchResult,
  },

  // admin
  {
    path: "/admin/property",
    name: "CreateProperty",
    component: CreateProperty,
    beforeEnter: ifIsAdmin,
  },
  {
    path: "/admin/post",
    name: "CreatePost",
    component: CreatePost,
    beforeEnter: ifIsAdmin,
  },
  {
    path: "/admin/contact",
    name: "Contact",
    component: Contact,
    beforeEnter: ifIsAdmin,
  },
  {
    path: "/admin/customise-carousel",
    name: "CustomiseCarousel",
    component: CustomiseCarousel,
    beforeEnter: ifIsAdmin,
  },
  //404
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
