import mock from "../mock";
import { store } from "@/store";
import jwtDecode from "jwt-decode";
import userData from "@/@fake-db/jwt";
import axios from "axios";

const data = {
  properties: [
    {
      _id: "1",
      option: "for_rent",
      type: "apartment",
      title: "Aphelo 1",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      recommendation:
        "The house has 2 bedroom and 2 bathroom with bath-tub and walk-in shower. It has a lot of natural light, quiet and clean.",
      living_expense: ["wifi"],
      plan: "1ldk",
      condition: ["school", "bus station", "gym"],
      equipment: ["TV", "Shower"],
      bedroom: 2,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
    },
    {
      _id: "2",
      option: "for_rent",
      type: "house",
      title: "Aphelo 2",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "2ldk",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "3",
      option: "for_rent",
      type: "house",
      title: "Aphelo 3",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "3ldk",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "4",
      option: "for_rent",
      type: "office",
      title: "Aphelo 4",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "studio",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "5",
      option: "for_rent",
      type: "office",
      title: "Aphelo 5",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "1dk",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "6",
      option: "for_sale",
      type: "apartment",
      title: "Aphelo 6",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "1k",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "7",
      option: "for_sale",
      type: "house",
      title: "Aphelo 7",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "2k",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "8",
      option: "for_sale",
      type: "house",
      title: "Aphelo 8",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "2dk",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "9",
      option: "for_sale",
      type: "house",
      title: "Aphelo 9",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "4ldk",
      condition: ["school", "gym", "pets allowed"],
      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
    {
      _id: "10",
      option: "for_sale",
      type: "house",
      title: "Aphelo 10",
      sector: 3,
      address: "Pham Hung str.",
      square: 120,
      cost: 700,
      utility_costs: 0,
      wifi_cost: 0,
      deposit: "400",
      contract_period: "long",
      plan: "4ldk",
      condition: ["school", "gym", "pets allowed"],

      equipment: ["TV", "Shower"],
      bedroom: 3,
      bathroom: 2,
      images: [
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
        require("@/assets/properties/apartment3.jpg"),
        require("@/assets/properties/apartment5.jpg"),
        require("@/assets/properties/apartment6.jpg"),
        require("@/assets/properties/apartment4.jpg"),
        require("@/assets/properties/apartment1.jpg"),
        require("@/assets/properties/apartment2.jpg"),
      ],
      createdAt: "17/11/2021 14:40:35",
      recommendation:
        "There is a modern kitchen with built-in oven, dish washer, microwave and kitchenware.",
      living_expense: ["wifi"],
      modifiedAt: "17/11/2021 14:40:35",
    },
  ],
};

mock.onPost("/property/create").reply((config) => {
  const { property } = JSON.parse(config.data);
  const { length } = data.property;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.property[length - 1]._id;
  }
  property._id = (parseInt(lastIndex) + 1).toString;
  data.properties.push(property);
  return [201, { property }];
});

mock
  .onGet("/property-limit")
  .reply(() => [200, data.properties.reverse().slice(0, 3)]);

//GET: Return single property
mock.onGet(/\/property\/\d+/).reply((config) => {
  // Get event _id from URL
  let propertyId = config.url.substring(config.url.lastIndexOf("/") + 1);

  const propertyIndex = data.properties.findIndex((e) => e._id === propertyId);
  const property = data.properties[propertyIndex];

  if (property) return [200, { property: property }];
  return [404];
});

//POST: filter
mock.onPost("/filter?page=1&size=8").reply((config) => {
  console.log(config);
  console.log(config.data);
  const a = JSON.parse(config.data);
  const property = data.properties.filter((item) => {
    return (
      a.plan.includes(item.plan) &&
      a.option.includes(item.option) &&
      a.type.includes(item.type)
    );
  });

  console.log("a", property);
  const filterResult = {
    properties: property,
    totalItems: property.length,
  };
  return [200, filterResult];
});

//GET: history
mock.onGet("/user/history?page=1&size=8").reply(() => {
  if (store.state.token) {
    const email = jwtDecode(store.state.token).email;

    const user = userData.users.filter((user) => user.email === email);
    const history = user[0].history.map((item) => {
      return data.properties[parseInt(item) - 1];
    });

    console.log("a", history);
    const result = {
      properties: history,
      totalItems: history.length,
    };

    return [200, result];
  }
});

//GET: favourite
mock.onGet("/user/favourite?page=1&size=8").reply(() => {
  if (store.state.token) {
    const email = jwtDecode(store.state.token).email;

    const user = userData.users.filter((user) => user.email === email);
    const favourite = user[0].favourite.map((item) => {
      return data.properties[parseInt(item) - 1];
    });

    console.log("a", favourite);
    const result = {
      properties: favourite,
      totalItems: favourite.length,
    };

    return [200, result];
  }
});

mock.onPost("/explore?page=1&size=4").reply(() => {
  const result = {
    properties: data.properties.slice(0, 4),
    totalItems: 4,
  };
  return [200, result];
});

mock
  .onGet("/search/")
  .reply(() => [200, data.properties.reverse().slice(0, 3)]);

mock
  .onGet("/post?page=1")
  .reply(() => [200, data.properties.reverse().slice(0, 3)]);
