<template>
  <div class="main-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
  .main-wrapper {
    overflow-x: hidden;
  }
</style>
