import mock from "./mock";

// JWT
import "./jwt";
import "./jwt/index"

//Data
import "./data/property";
import "./data/post";
import "./data/contact"

mock.onAny().passThrough();
