import mock from "../mock";
import jwt from "jsonwebtoken";
const data = {
  users: [
    {
      _id: 1,
      email: "admin@demo.com",
      name: "Galen Slixby",
      given_name: "Galen",
      family_name: "Slixby",
      password: "admin",
      gender: "male",
      created_date: "2021-11-24 11:55:37",
      modified_date: "2021-11-24 11:55:37",
      role: "admin",
      history: ["1", "2"],
      favourite: ["1", "2"],
    },
    {
      _id: 2,
      email: "client@demo.com",
      name: "Halsey Redmore",
      given_name: "Halsey",
      family_name: "Redmore",
      password: "client",
      gender: "male ",
      created_date: "2021-11-24 11:56:39",
      modified_date: "2021-11-24 11:56:39",
      role: "user",
      history: ["1", "2"],
      favourite: ["1", "2"],
    },
  ],
};

const jwtConfig = {
  secret: "dd5f3089-40c3-403d-af14-d0c228b05cb4",
  expireTime: "30m",
};

mock.onPost("/user/login").reply((request) => {
  const obj = JSON.parse(request.data);
  const email = obj.email;
  const password = obj.password;

  let error = {
    email: ["Something went wrong"],
  };

  const user = data.users.find(
    (u) => u.email === email && u.password === password
  );

  console.log("USER:", user);

  if (user) {
    const access_token = jwt.sign(
      {
        _id: user._id,
        email: user.email,
        role: user.role,
        name: user.given_name + user.family_name,
      },
      jwtConfig.secret,
      {
        expiresIn: jwtConfig.expireTime,
      }
    );
    console.log(access_token);
    return [200, { access_token: access_token }];
  } else {
    error = {
      email: ["Email or Password is Inval_id"],
    };
  }

  return [400, { error }];
});

mock.onPost("/contact").reply(() => {
  return [200, { message: "success" }];
});

export default data;
